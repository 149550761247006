<template lang="pug">
.safety-page
	h1 Safety
</template>

<script>
export default {
	name: "Safety"
}
</script>

<style lang="scss" scoped>
.safety-page {
	h1 {
		text-align: center;
	}
}
</style>
